import { IAdminImage, ICompilationSeo, ICompilationInfo } from "../../models/admin";
import { Injectable } from "@angular/core";
import { IAdminCompilation, ICreateCompilation } from "../../models/admin";
import { BaseRequestService, Paginated } from "../base-request.service";

export type GetCompilationsParams = {
  page?: number,
  pageSize?: number,
};

export type ChangeCompilationContentDto = {
  added?: Array<IAdminImage["id"]>;
  removed?: Array<IAdminImage["id"]>;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompilationService {
  constructor(private requestService: BaseRequestService) {}

  deleteTagFromComp(tagId: number, compilationTag: any) {
    this.requestService.delete(`/tags/compilations/${tagId}`, compilationTag);
  }

  //////////////////////////////////
  // ASYNC FUNCTIONS FOR COMPILATIONS CRUD

  async getCompilations(page?: number, pageSize?: number): Promise<Paginated<IAdminCompilation>> {
    const params = new URLSearchParams();
    if (page) {
      params.set('page', String(page));
    }
    if (pageSize) {
      params.set('pageSize', String(pageSize));
    }

    let url = '/compilations';
    if ([...params.keys()].length) {
      url += '?' + params.toString();
    }

    return this.requestService.get<Paginated<IAdminCompilation>>(url);
  }

  createCompilation(formData: ICreateCompilation) {
    return this.requestService.post<IAdminCompilation>(
      "/compilations/create",
      formData
    );
  }

  updateCompilation(
    id: number,
    formData: ICreateCompilation
  ): Promise<ICompilationInfo> {
    return this.requestService.patch<ICompilationInfo>(
      `/compilations/${id}`,
      formData
    );
  }

  getCompilationImages(compilationId: number): Promise<Paginated<IAdminImage>> {
    return this.requestService.get<Paginated<IAdminImage>>(
      `/compilations/pagination/${compilationId}/-1`
    );
  }

  getCompilationInfo(id: number): Promise<ICompilationInfo> {
    return this.requestService.get<ICompilationInfo>(`/compilations/${id}`);
  }

  deleteCompilation(id: number) {
    return this.requestService.delete<IAdminCompilation>(`/compilations/${id}`);
  }

  addImages(compilationId: number, images: number[]) {
    return this.requestService.patch<IAdminCompilation>(
      `/compilations/addImages/${compilationId}`,
      { imagesId: images }
    );
  }

  deleteImageFromCompilation(compilationId: number, imageid: number) {
    return this.requestService.delete<IAdminCompilation>(
      `/compilations/deleteImage/${compilationId}`,
      { imageId: imageid }
    );
  }

  addSeoToCompilation(compilationSeo: any) {
    return this.requestService.post<ICompilationSeo>(
      "/seoInfo/addToCompilation",
      compilationSeo
    );
  }

  changeCompilationContent(compilationId: number, change: ChangeCompilationContentDto): Promise<any> {
    return this.requestService.patch<any>(
      `/compilations/${compilationId}/images`,
      change,
    );
  }
}
